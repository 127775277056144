:root {
  --white: #ffffff;
  --bg: #F3FAFD;
  --black: #000000;
  --grey: #FBFBFB;
  --grey-dark: #AEB0AD;
  --emerald: #02818A;
  --light-emerald: #D5EDEF;
  --orange: #FD5A42;
}

html {
   font-size: 1.111vw;
   width: 100%;
   -webkit-font-smoothing: antialiased;
}

body {
   position: relative;
   width: 100%;
   font-family: 'Inter';
   font-optical-sizing: auto;
   font-variant-numeric: lining-nums;
   font-size: 1.38rem;
   line-height: 1.88rem;
   color: var(--black);
   transition: opacity ease 0.5s;
   -webkit-font-smoothing: antialiased;
   scroll-behavior: smooth;
   transition: opacity ease 0.3s;
}

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   flex-shrink: 0;
}

sub, sup {
   line-height: 0;
   font-size: 0.7em;
}

#root {
   position: relative;
   width: 100%;
   z-index: 10;
}

span {
   user-select: none;
}

.wrap {
   position: relative;
   width: 100%;
}

.container {
   position: relative;
   width: 100%;
   padding: 0 1.75rem;
}
.grid{
   position: relative;
   width: 100%;
   display: grid;
   grid-template-columns: repeat(16, 1fr);
}
a{
   text-decoration: none;
   transform: translateZ(0);
}
img, svg, picture {
   height: auto;
   width: 100%;
   flex-shrink: 0;
}
picture{
   display: flex;
   align-items: flex-start;
}
span, p, input, button, a, ul {
   font: inherit;
   color: inherit;
}