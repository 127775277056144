.body{
	margin-top: 1.5rem;
}
.sort {
    padding-bottom: 1rem;
    position: sticky;
    left: 0;
    top: 13.3rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.19rem;
    gap: 0.5rem;
    background: var(--white);
    z-index: 1001;
    &__btn {
        position: relative;
        cursor: pointer;
        font-weight: 300;
        transition: color ease 0.2s;
        &.active {
            &::after {
                content: '';
                top: 100%;
                left: 0;
                width: 100%;
                position: absolute;
                border-bottom: 0.065rem solid var(--black);
                transition: border ease 0.2s;
            }
        }
        &:hover {
            color: var(--orange);
            &::after {
                border-color: var(--orange);
            }
        }
    }
}
.wrap {
    width: 100%;
    height: 100%;
}