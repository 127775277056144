.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    height: 1.5rem;
    padding: 0 0.25rem;
    position: absolute;
    border-radius: 0.25rem;
    color: #9D9D9D;
    background: var(--light-emerald);
    font-size: 0.75rem;
    line-height: 1.25rem;
    &_1{
        background: var(--emerald);
        color: var(--white);
    }
}