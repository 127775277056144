.bars {
    padding: 0rem 0rem 2rem;
    top: 0;
    left: 0;
    position: relative;
    width: 100%;
    z-index: 9;
}

.body {
    position: relative;
    min-height: 10rem;
    max-height: 100%;
    width: 100%;
    display: flex;
}

.border {
    position: sticky;
    left: 0;
    top: 0;
    height: 1rem;
    width: 100%;
    background: var(--white);
    transform: translateZ(0);
    z-index: 1000;
    &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 0;
        height: 2rem;
        width: 100%;
        background: var(--white);
    }
}
.collapse {
    padding-top: 2.75rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--grey);
    // background: var(--emerald);
    transform: translateZ(0);
    &__body {
        width: 100%;
        overflow: hidden;
        transition: height ease 0.3s;
    }
    &__inner {
        padding: 0.81rem 0;
        display: flex;
        flex-direction: column;
    }
    &+.collapse {
        margin-top: 1.75rem;
    }
}

.col {
    position: relative;
    min-height: 1.50rem;
    &+.col {
        margin-top: 1.13rem;
    }
}

.desc {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 0;
    font-size: 0.75rem;
    color: var(--black);
    cursor: pointer;
    &__icon{
        position: relative;
        padding-top: 0.1rem;
        left: 0.7rem;
        bottom: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0.94rem;
        width: 0.94rem;
        border-radius: 50%;
        background: #EBEBEB;
        transform: translateZ(0);
    }
    &__popup {
        padding: 0.63rem;
        position: absolute;
        left: 0;
        top: calc(100% + 0.5rem);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        pointer-events: none;
        width: 22.63rem;
        font-size: 0.88rem;
        line-height: 120%;
        font-weight: 300;
        color: var(--black);
        background: var(--white);
        border: 0.07rem solid #EBEBEB;
        border-radius: 0.25rem;
        box-shadow: 0rem 0rem 1.5rem rgba(0, 0, 0, 0.08);
        transform: translateY(-0.5rem);
        opacity: 0;
        transition: all ease 0.3s;
        z-index: 10000000;
        span {
            white-space: nowrap;
        }
    }
    &:hover {
        .desc__popup {
            transform: translateY(0rem);
            opacity: 1;
        }
    }
}

.img {
    position: absolute;
    top: -10.5rem;
    left: 7.56rem;
    width: 75.85913rem;
    height: 38.47713rem;
    z-index: -1;
    pointer-events: none;
}

.info {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 25rem;
    z-index: 100;
    background: linear-gradient(90deg, #FFF 72.92%, rgba(255, 255, 255, 1) 89.81%, rgba(255, 255, 255, 0) 100%);
    &__names {
        width: 20.125rem;
    }
    &__more {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 4rem 0 1.75rem 1.14rem;
        font-size: 1.25rem;
        line-height: 1.6875rem;
        background: var(--white);
        width: 21.3rem;
        cursor: pointer;
    }
    &__wrap {
        padding: 1.44rem 1.25rem 8.44rem;
        width: 100%;
        max-height: 38rem;
        overflow: hidden;
    }
}

.inner {
    position: static;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
}

.grid {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 10;
    display: flex;
    pointer-events: none;
}

.header {
    position: sticky;
    left: 0;
    top: 13.28rem;
    width: 100%;
    height: 5.8125rem;
    z-index: 1000;
    transform: translateZ(0);
    overflow: hidden;
    background: var(--white);
    &::after {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 100%;
        width: 25.81rem;
        background: var(--white);
        z-index: 10;
    }
    &__title {
        position: relative;
        padding: 0.81rem 1.25rem;
        display: flex;
        align-items: flex-end;
        width: 25rem;
        height: 100%;
        background: linear-gradient(90deg, #FFF 72.92%, rgb(255, 255, 255) 89.81%, rgba(255, 255, 255, 0) 100%);
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.6875rem;
        z-index: 100;
    }
}

.led {
    position: sticky;
    left: 0;
    top: 15.3rem;
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.88rem;
    height: 1.94rem;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.31rem;
    letter-spacing: -0.02em;
    cursor: pointer;
    border: 0.07rem solid var(--black);
    border-radius: 0.38rem;
    z-index: 1001;
    transition: all ease 0.3s;
    &__icon {
        margin-right: 0.36rem;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background: var(--orange);
        transform: translateZ(0);
    }
    &.hidden {
        .led__icon {
            background: var(--grey-dark);
        }
    }
    
    &:hover {
        color: var(--orange);
        border-color: var(--orange);
    }
}

.months {
    position: absolute;
    bottom: 0;
    left: 25.81rem;
    min-height: 2.43rem;
    display: flex;
    z-index: 10;
}

.month {
    position: relative;
    min-width: 6.88rem;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 1.25rem;
    line-height: 1.6875rem;
    font-weight: 300;
    color: var(--emerald);
    border-left: 0.06rem solid rgba(1, 43, 52, 0.1);
    &_first {
        border-left: 0.06rem solid rgba(1, 43, 52, 0.1);
    }
    &_header {
        .month__sub {
            opacity: 0;
        }
        &_0 {
            align-items: flex-end;
            height: 2.43rem;
            .month__sub {
                height: 1.69rem;
            }
            .month__name {
                top: 0;
                left: 0.3rem;
                transform: none;
                line-height: 0.75rem;
                font-size: 0.75rem;
            }
        }
    }
    &__sub {
        &+.month__sub {
            border-left: 0.06rem dashed rgba(1, 43, 52, 0.1);
        }
        &_0 {
            width: 3.44rem;
        }
        &_1 {
            width: 3.44rem;
        }
        &_2 {
            width: 100%;
            flex-shrink: unset;
        }
        &_3 {
            width: 4.63rem;
        }
    }
    &__name {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.6;
    }
}

.scroll {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    &__border {
        position: sticky;
        left: 0;
        top: calc(100% - 0.5rem);
        height: 0.5rem;
        width: 100%;
        background: var(--white);
        transform: translateZ(0);
        z-index: 1000;
        &::after {
            position: absolute;
            content: '';
            top: 50%;
            left: 0;
            height: 2rem;
            width: 100%;
            background: var(--white);
        }
    }
}

.screen {
    display: flex;
    position: relative;
    margin-left: auto;
    min-height: 21rem;
    height: calc(100% - 0.5rem);
    // max-height: calc(100vh - 16.5rem);
    width: calc(100% - 25.81rem);
    overflow: hidden;
    &_scroll {
        position: sticky;
        right: 0;
        top: 19.5rem;
        max-height: calc(100vh - 20rem);
        overflow: auto;
        z-index: 100;
        * {
            opacity: 0;
        }
    }
    &::-webkit-scrollbar {
        width: 0.625rem;
        height: 0.625rem;
    }
    &::-webkit-scrollbar-track {
        margin-right: 3rem;
        background-color: #FBFBFB;
        border-radius: 6.25rem;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 6.25rem;
        background: #EBEBEB;
    }
}

.sort {
    position: sticky;
    left: 0;
    top: 13.3rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.19rem;
    gap: 0.5rem;
    z-index: 1001;
    background: var(--white);
    transform: translateZ(0);
    &__btn {
        position: relative;
        cursor: pointer;
        font-weight: 300;
        transition: color ease 0.2s;
        &.active {
            &::after {
                content: '';
                top: 100%;
                left: 0;
                width: 100%;
                position: absolute;
                border-bottom: 0.065rem solid var(--black);
                transition: border ease 0.2s;
            }
        }
        &:hover {
            color: var(--orange);
            &::after {
                border-color: var(--orange);
            }
        }
    }
}

.table {
    display: flex;
    flex-direction: column;
    margin-top: -3.7rem;
    position: relative;
    width: 100%;
    background: var(--white);
    // overflow: hidden;
}

.titles {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    z-index: 100;
    pointer-events: none;
    // &::after {
    //     position: absolute;
    //     content: '';
    //     left: 0;
    //     top: -5.8125rem;
    //     width: 100%;
    //     height: 5.8125rem;
    //     background: var(--white);
    // }
    &__inner {
        width: 25.81rem;
        pointer-events: auto;
    }
}

.title {
    font-size: 1rem;
    line-height: 1.19rem;
    min-height: 1.50rem;
    max-width: 24.00rem;
}

.today {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(100% - 25.81rem);
    z-index: 1001;
    pointer-events: none;
    &__wrapper {
        position: sticky;
        top: 13.5rem;
        right: 0;
        overflow: hidden;
        width: 100%;
        max-height: calc(100vh - 14rem);
    }
    &__body {
        position: absolute;
        top: 3.3rem;
        height: calc(100% - 4rem);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__date, &__count {
        position: absolute;
        display: flex;
        white-space: nowrap;
        height: 1.7rem;
        left: 50%;
        top: -2.3rem;
        transform: translate(-50%);
        font-size: 1.25rem;
        line-height: 1.7rem;
    }
    &__count {
        top: unset;
        bottom: -1.85rem;
    }
    &__line {
        position: relative;
        height: 100%;
        min-width: 1px;
        width: 0.06rem;
        background: var(--black);
        &::after {
            position: absolute;
            content: '';
            top: -0.5rem;
            left: 50%;
            transform: translate(-50%);
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: var(--orange);
        }
    }
}

.year {
    position: absolute;
    top: -0rem;
    left: 0;
    height: 1.7rem;
    width: 0;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.69rem;
    text-align: center;
    color: var(--emerald);
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: absolute;
        background: var(--white);
        left: -50%;
        top: 0;
        transform: translate(-50%);
        opacity: 0.6;
    }
}

.wrap {
    padding-left: 1.75rem;
    width: 100%;
    height: 100%;
}