.btn {
    padding: 0 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.75rem;
    font-size: 1.25rem;
    line-height: 1.44rem;
    font-weight: 300;
    cursor: pointer;
    transition: all ease 0.3s;
    background: var(--white);
    color: var(--black);
    border: 0.07rem solid var(--black);
    border-radius: 0.5rem;
    &.active {
        background: var(--orange);
        color: var(--white);
        border-color: var(--orange);
        &:hover {
            background: var(--orange);
            color: var(--white);
            border-color: var(--orange);
        }
    }
    &:hover {
        color: var(--orange);
        border-color: var(--orange);
    }
}

.wrap {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.75rem;
}