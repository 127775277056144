.btn {
    cursor: pointer;
	transition: color ease 0.3s;
    &:nth-child(3) {
        color: #EBEBEB;
    }
}

.icon {
    position: relative;
    width: 3.25rem;
    height: 2rem;
    border-radius: 6.6rem;
    background: var(--emerald);
    cursor: pointer;
	&::after{
		position: absolute;
		content: '';
		left: 0.25rem;
		top: 50%;
		width: 1.50rem;
		height: 1.50rem;
		background: var(--white);
		border-radius: 50%;
		transform: translate(0%, -50%) translateZ(0);
		transition: transform ease 0.3s;
	}
}

.wrap {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    line-height: 3rem;
    gap: 1rem;
    font-weight: bold;
    &.hr {
        .btn {
            &:nth-child(1) {
                color: #EBEBEB;
            }
            &:nth-child(3) {
                color: var(--black);
            }
        }
		.icon {
			&::after{
				transform: translate(calc(100% - 0.25rem), -50%) translateZ(0);
			}
		}
    }
}