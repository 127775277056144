.body {
    position: relative;
}

.cover {
    display: flex;
    height: 18.00rem;
    width: 100%;
    &__info {
        padding-bottom: 3.38rem;
        height: 100%;
        width: 18.13rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &__title {
        font-size: 2.50rem;
        line-height: 3rem;
        font-weight: 600;
        width: 14.3rem;
        span {
            color: var(--orange);
        }
    }
    &__img {
        height: 100%;
        width: 100%;
        flex-shrink: unset;
        img {
            height: 100%;
            object-fit: cover;
        }
    }
}

.header {
    margin-top: -3.38rem;
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
	color: var(--grey);
	&.fixed{
		.header__bg{
			max-width: 95rem;
		}
		.header__desc{
			transition-delay: 0.3s;
			opacity: 1;
		}
	}
	&__inner{
		display: flex;
		align-items: center;
		height: 3.38rem;
		width: 100%;
	}
	&__logo{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 7.50rem;
	}
	&__desc{
		position: relative;
		margin-left: 2.13rem;
		font-size: 1rem;
		text-transform: uppercase;
		font-weight: 300;
		opacity: 0;
		transition: opacity ease 0.3s;
		&::before{
			position: absolute;
			content: '';
			top: 50%;
			left: -1.31rem;
			transform: translateY(-50%) translateZ(0);
			width: 0.5rem;
			height: 0.5rem;
			border-radius: 50%;
			background: var(--grey);
		}
	}
	&__bg{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
        max-width: 18.13rem;
		height: 100%;
		background: var(--emerald);
		transition: max-width ease 0.3s;
		z-index: -1;
	}
}

.panel {
    position: sticky;
    left: 0;
    top: 3.38rem;
    background: var(--white);
    padding-top: 2.13rem;
    padding-bottom: 0.75rem;
    z-index: 1000;
	&::after{
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		height: calc(100% + 2rem);
		width: 100%;
		background: var(--white);
		z-index: -1;
	}
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.title {
    font-size: 2.50rem;
    line-height: 3rem;
    color: var(--emerald);
}

.type {
    margin-top: 1.25rem;
}

.wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
	min-height: calc(100vh + 18rem);
}