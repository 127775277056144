.date {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    width: 16.25rem;
}

.desc {
    padding-top: 0.03rem;
    margin: 0 0.25rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0.94rem;
    width: 0.94rem;
    font-size: 0.75rem;
    border-radius: 6.9rem;
    color: var(--emerald);
    background: var(--white);
    cursor: pointer;
    &__popup {
        padding: 0.63rem;
        position: absolute;
        left: 0;
        top: calc(100% + 0.5rem);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        pointer-events: none;
        width: 22.63rem;
        font-size: 0.88rem;
        line-height: 120%;
        font-weight: 300;
        color: var(--black);
        background: var(--white);
        border: 0.07rem solid #EBEBEB;
        border-radius: 0.25rem;
        box-shadow: 0rem 0rem 1.5rem rgba(0, 0, 0, 0.08);
        transform: translateY(-0.5rem);
        opacity: 0;
        transition: all ease 0.3s;
        z-index: 10000000;
        span {
            white-space: nowrap;
        }
    }
    &:hover {
        .desc__popup {
            transform: translateY(0rem);
            opacity: 1;
        }
    }
}

.help {
    padding-bottom: 0.03rem;
    margin-left: 0.75rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0.94rem;
    width: 3.56rem;
    font-size: 0.75rem;
    border-radius: 6.9rem;
    color: var(--emerald);
    background: var(--white);
    cursor: pointer;
    &__popup {
        padding: 0.63rem;
        position: absolute;
        left: 0;
        top: calc(100% + 0.5rem);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        pointer-events: none;
        min-width: 10.56rem;
        font-size: 0.88rem;
        line-height: 120%;
        font-weight: 300;
        color: var(--black);
        background: var(--white);
        border: 0.07rem solid #EBEBEB;
        border-radius: 0.25rem;
        box-shadow: 0rem 0rem 1.5rem rgba(0, 0, 0, 0.08);
        transform: translateY(-0.5rem);
        opacity: 0;
        transition: all ease 0.3s;
        z-index: 10000000;
        span {
            white-space: nowrap;
            &+span{
                margin-top: 0.3rem;
            }
        }
    }
    &:hover {
        .help__popup {
            transform: translateY(0rem);
            opacity: 1;
        }
    }
}

.name{
    max-width: 40.69rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.wrap {
    display: flex;
    align-items: center;
    height: 2.56rem;
    padding: 0 1.25rem;
    width: 61.88rem;
    border-radius: 0.50rem;
    color: var(--black);
    background: var(--light-emerald);
    font-size: 1.00rem;
    line-height: 1.25rem;
    &_1 {
        background: var(--emerald);
        color: var(--white);
    }
    &+.wrap {
        margin-top: 0.75rem;
    }
}