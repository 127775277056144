.body {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height ease 0.3s;
}

.desc {
    margin-left: 0.25rem;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    color: var(--black);
    cursor: pointer;
    white-space: unset;
    &__icon{
        position: relative;
        padding-top: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0.94rem;
        width: 0.94rem;
        border-radius: 50%;
        background: #EBEBEB;
        transform: translateZ(0);
    }
    &__popup {
        padding: 0.63rem;
        position: absolute;
        left: 0;
        top: calc(100% + 0.5rem);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        pointer-events: none;
        width: 22.63rem;
        font-size: 0.88rem;
        line-height: 120%;
        font-weight: 300;
        color: var(--black);
        background: var(--white);
        border: 0.07rem solid #EBEBEB;
        border-radius: 0.25rem;
        box-shadow: 0rem 0rem 1.5rem rgba(0, 0, 0, 0.08);
        transform: translateY(-0.5rem);
        opacity: 0;
        transition: all ease 0.3s;
        z-index: 10000000;
        white-space: normal;
        span {
            white-space: nowrap;
        }
    }
    &:hover {
        .desc__popup {
            transform: translateY(0rem);
            opacity: 1;
        }
    }
}
.inner {
    padding: 0.81rem 0;
    width: 100%;
}

.header {
    padding-top: 0.24rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.50rem;
    line-height: 1.81rem;
    font-weight: 500;
    height: 2.75rem;
    cursor: pointer;
    white-space: nowrap;
    &__counter {
        padding-left: 0.3rem;
        color: #AEB0AD;
        max-width: 3em;
        overflow: hidden;
        transition: max-width ease 0.3s;
    }
    svg {
        margin-left: 0.5rem;
        width: 1rem;
        transition: transform ease 0.3s;
    }
}

.wrap {
    position: relative;
    width: 100%;
    background: var(--grey);
    transform: translateZ(0);
    // background: var(--emerald);
    &:hover{
        z-index: 100;
    }
    &_list{
        background: none;
        position: relative;
        // z-index: 1000;
    }
    &.open {
        .header {
            svg {
                transform: rotate(180deg);
            }
            &__counter {
                max-width: 0rem;
            }
        }
    }
    &.init {
        .header__counter {
            transition: none;
        }
        .body {
            transition: none;
        }
        svg {
            transition: none;
        }
    }
    &+.wrap {
        margin-top: 1.75rem;
    }
}